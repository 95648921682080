import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const CanonicalUrl = () => {
    const location = useLocation();

    // Construct the canonical URL using the current location
    const canonicalUrl = `https://otocloud.io${location.pathname}`;

    return (
        <Helmet>
            <link rel="canonical" href={canonicalUrl} />
        </Helmet>
    );
};

export default CanonicalUrl;
