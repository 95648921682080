import React from 'react';
import { hydrate, render } from 'react-dom';

import ReactGA from 'react-ga4';
import App from './App';
import './assets/css/custom-animated.css';
import './assets/css/default.css';
import './assets/css/font-awesome.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/magnific.dark.css';
import './assets/css/main.css';
import './assets/css/style.css';

import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');

ReactGA.initialize('G-MMR707LN6Y');

if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}

const SendAnalytics = () => {
    ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
    });
};

reportWebVitals(SendAnalytics);
