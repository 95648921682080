import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Routes from './Routes';

function App() {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey="6Lc0_FYlAAAAALtLnlgYbZJSU8xIcM6QektrreVi"
            language="en"
            useEnterprise="false"
        >
            <Routes />
        </GoogleReCaptchaProvider>
    );
}

export default App;
