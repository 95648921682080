import React, { lazy, Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import CanonicalUrl from './components/Helper/CanonicalUrl';
import ScrollToTop from './components/Helper/ScrollToTop';
import Preloader from './components/Preloader';

const Home = lazy(() => import('./components/Home'));
const Backoffice = lazy(() => import('./components/Apps/Backoffice'));
const Frontdesk = lazy(() => import('./components/Apps/Frontdesk'));
const Inventory = lazy(() => import('./components/Apps/Inventory'));
const POS = lazy(() => import('./components/Apps/pos'));
const AboutUsTwo = lazy(() => import('./components/About/AboutUsTwo'));
const Contact = lazy(() => import('./components/Contact'));
const Partnership = lazy(() => import('./components/Partnership'));
const Apps = lazy(() => import('./components/Apps'));
const Error = lazy(() => import('./components/Error'));

function AppRoutes() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className="appie-visible active">
            <Router>
                <CanonicalUrl />

                <Suspense fallback={<Preloader />}>
                    <ScrollToTop>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/apps" element={<Apps />} />
                            <Route path="/apps/pos" element={<POS />} />
                            <Route exact path="/apps/frontdesk" element={<Frontdesk />} />
                            <Route
                                exact
                                path="/apps/inventory-management"
                                element={<Inventory />}
                            />
                            <Route path="/apps/backoffice" element={<Backoffice />} />
                            <Route exact path="/contact" element={<Contact />} />
                            <Route exact path="/about" element={<AboutUsTwo />} />
                            <Route exact path="/partnership" element={<Partnership />} />
                            <Route path="*" element={<Error />} />
                        </Routes>
                    </ScrollToTop>
                </Suspense>
            </Router>
        </div>
    );
}

export default AppRoutes;
